import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const EmailModuleContainer = styled(MainColumn)`
  position: relative;
  padding: 70px 0;
  margin-top: 40px;
  padding-bottom: ${props => props.feedbackShown && 0};

  @media ${device.desktop} {
    margin-top: 120px;
  }
  :before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: 100%;
    width: 100vw;
    z-index: -1;
    background: #f7f7f7;
  }
  @media ${device.tablet} {
    flex-direction: row;
    display: ${props => props.feedbackShown && "none"};
  }
`

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    padding-right: 20px;
  }
  h3 {
    color: black;
    margin-bottom: 10px;
    font-size: 24px;
  }
  p {
    color: black;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 400;
  }

  @media ${device.tablet} {
    width: 50%;
  }
`

export const BlueButton = styled.button`
  text-decoration: underline;
  color: #55789B;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
`

export const RightSection = styled(InputSection)`
  margin-top: 60px;
  border-top: ${props => props.feedback && "2px solid #d8d8d8"};
  margin-top: ${props => props.feedback && "30px"};
  padding-top: ${props => props.feedback && "30px"};
  @media ${device.tablet} {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
    padding-right: 0px;
    padding-left: 20px;
    width: ${props => props.feedback && "auto"};
    margin-left: ${props => props.feedback && "auto"};
    padding-right: ${props => props.feedback && "40px"};
  }

  @media ${device.large} {
    padding-right: ${props => props.feedback && "120px"};
  }
  ul {
    list-style: none;
    margin-top: 30px;

    a {
      color: #55789B;
      text-decoration: underline;
      margin-bottom: 10px;
      display: block;
      font-size: 18px;
      font-weight: 500;
    }
  }
`

export const FeedbackButtons = styled.div`
  display: flex;
  button:first-of-type {
    margin-right: 30px;
  }

  button {
    font-size: 20px;
  }
`
export const FeedbackPrompt = styled.p`
  font-size: 22px !important;
  margin-bottom: 30px;
  color: black;
`
