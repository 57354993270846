import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const WarningModuleContainer = styled(MainColumn)`
  position: relative;
  padding: 60px 0;
  :before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: 100%;
    width: 100vw;
    z-index: -1;
    background: #fdf0af;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    padding-right: 60px;
  }
  h3 {
    color: black;
    margin-bottom: 20px;
    font-size: 28px;
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
      align-items: center;
      flex-direction: row;
    }
    svg {
      width: 36px;
      height: 36px;
      margin-bottom: 25px;
      display: block !important;
      flex-shrink: 0;
      @media ${device.tablet} {
        display: inline;
        margin-bottom: 0px;
        margin-right: 20px;
      }
    }
  }
  p {
    color: black;
    font-size: 18px;
    @media ${device.tablet} {
      padding-left: 56px;
    }
  }

  @media ${device.tablet} {
    width: 50%;
  }
`
