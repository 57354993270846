import React, { useState, useEffect } from "react"
import {
  FeedbackModuleContainer,
  InputSection,
  RightSection,
  FeedbackPrompt,
  BlueButton,
} from "./style"
import { FineForm } from "../FineForm"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

const SEND_FEEDBACK = gql`
  mutation sendFeedback($feedback: String!) {
    sendFeedback(
      input: { clientMutationId: "Feedback Submission", feedback: $feedback }
    ) {
      data
      success
    }
  }
`
const FeedbackModule = ({
  setFeedbackShown,
  submitted,
  setFeedbackSubmitted,
}) => {
  const [sendFeedback] = useMutation(SEND_FEEDBACK)
  const [feedbackMessage, setFeedbackMessage] = useState("")
  console.log("feedbackMessage:", feedbackMessage)

  useEffect(() => {
    if (feedbackMessage.length) {
      setFeedbackShown(false)
      sendFeedback({
        variables: {
          feedback: feedbackMessage,
        },
      })
    }
  }, [feedbackMessage])

  return (
    <FeedbackModuleContainer>
      <InputSection>
        <h3>Thanks for letting us know</h3>

        <FeedbackPrompt>
          Can you tell us how we can improve this page?
        </FeedbackPrompt>

        <FineForm
          feedback
          submitted={submitted}
          setSubmitted={setFeedbackSubmitted}
          setFeedbackMessage={setFeedbackMessage}
        />
      </InputSection>
      <RightSection>
        <BlueButton onClick={() => setFeedbackShown(false)}>Close</BlueButton>
      </RightSection>
    </FeedbackModuleContainer>
  )
}

export default FeedbackModule
