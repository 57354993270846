import React from "react"
import { ReactComponent as Airplane } from "../../images/airplane.svg"
import { TopDoublePanel } from "components/TopDoublePanel"
import { ReactComponent as FineTypeIcon } from "images/icons/fine-type.svg"

import {
  ReviewPageContainer,
  DoubleBlockContainer,
  DetailedSummaryContent,
  SectionDetailsContent,
  ImportantContent,
  Tip,
  FineOptionDetailsContent,
  FineSteps,
  Step,
  LinksAndFiles,
  LinkList,
  FileList,
  File,
  FileImg,
  FileDetails,
} from "./style"
import EmailModule from "../EmailModule/EmailModule"
import { WarningModule } from "../WarningModule"

const FineOption = ({ data }) => {
  console.log(data)

  const topRightContent = <Airplane />
  return (
    <ReviewPageContainer>
      <TopDoublePanel
        title={data.title}
        body={data.top.shortSummary}
        rightContent={topRightContent}
      />
      {data.top.summary && (
        <DoubleBlockContainer>
          <DetailedSummaryContent
            dangerouslySetInnerHTML={{ __html: data.top.summary }}
          />
        </DoubleBlockContainer>
      )}

      <DoubleBlockContainer>
        {data.top.whenShould && (
          <SectionDetailsContent>
            <div className="title">{data.top.sectionTitle}</div>
            <div dangerouslySetInnerHTML={{ __html: data.top.whenShould }} />
          </SectionDetailsContent>
        )}
        {data.top.thingsKnow && (
          <ImportantContent>
            <div className="title">
              <svg id="icon-info" viewBox="0 0 32 32">
                <title>info</title>
                <path d="M14.854 9.72h1.829v-1.829h-1.829z"></path>
                <path d="M14.854 11.548v12.096h1.829v-12.096z"></path>
                <path
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.8286"
                  strokeMiterlimit="4"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  d="M30.622 15.768c0 8.204-6.65 14.854-14.854 14.854s-14.854-6.65-14.854-14.854c0-8.204 6.65-14.854 14.854-14.854s14.854 6.651 14.854 14.854z"
                ></path>
              </svg>
              Important things to know
            </div>

            {data.top.thingsKnow.map((thing, index) => {
              return (
                <Tip key={index}>
                  <h3>{thing.title}</h3>
                  <p>{thing.description}</p>
                </Tip>
              )
            })}
          </ImportantContent>
        )}
      </DoubleBlockContainer>
      <DoubleBlockContainer>
        <FineOptionDetailsContent>
          <div className="title">{data.details.detailTitle}</div>

          <div
            dangerouslySetInnerHTML={{ __html: data.details.detailDescription }}
          />
        </FineOptionDetailsContent>
      </DoubleBlockContainer>
      {data.steps.steps && (
        <DoubleBlockContainer>
          <SectionDetailsContent>
            <div className="title">{data.steps.stepHeading}</div>

            <FineSteps>
              {data.steps.steps.map((step, index) => {
                return (
                  <Step key={index}>
                    <h3>{step.stepTitle}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: step.stepDescription,
                      }}
                    />
                  </Step>
                )
              })}
            </FineSteps>
          </SectionDetailsContent>
        </DoubleBlockContainer>
      )}
      <DoubleBlockContainer>
        <LinksAndFiles>
          {data.linksAndFiles.linksTitle && (
            <div className="title">{data.linksAndFiles.linksTitle}</div>
          )}
          {data.linksAndFiles.links && (
            <LinkList>
              {data.linksAndFiles.links.map((link, index) => {
                return (
                  <li key={index}>
                    <a href={link.url}>{link.linkTitle}</a>
                  </li>
                )
              })}
            </LinkList>
          )}
          {data.linksAndFiles.filesTitle && (
            <div className="title">{data.linksAndFiles.filesTitle}</div>
          )}
          {data.linksAndFiles.files && (
            <FileList>
              {data.linksAndFiles.files.map((file, index) => {
                if (file.file) {
                  return (
                    <File key={index}>
                      <FileImg>
                        <FineTypeIcon />
                      </FileImg>

                      <FileDetails>
                        <a href={file.file.mediaItemUrl}>{file.file.title}</a>
                        {/* <p>Microsoft Word template</p> */}
                      </FileDetails>
                    </File>
                  )
                }
              })}
            </FileList>
          )}
        </LinksAndFiles>
      </DoubleBlockContainer>
      <EmailModule feedback />
      <WarningModule />
    </ReviewPageContainer>
  )
}

export default FineOption
