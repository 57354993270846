import React, { useState, useEffect } from "react"
import {
  EmailModuleContainer,
  InputSection,
  RightSection,
  BlueButton,
  FeedbackButtons,
} from "./style"
import { FineForm } from "../FineForm"
import { Link } from "gatsby"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { FeedbackModule } from "../FeedbackModule"

const SEND_EMAIL_LINK = gql`
  mutation SendEmailLink($email: String!, $url: String!) {
    sendEmailLink(
      input: {
        clientMutationId: "Email Results Submission"
        email: $email
        url: $url
      }
    ) {
      data
      success
    }
  }
`
const EmailModule = ({ feedback }) => {
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState("")
  const [sendEmailLink] = useMutation(SEND_EMAIL_LINK)
  const [helpful, setHelpful] = useState("")
  const [feedbackShown, setFeedbackShown] = useState(false)
  useEffect(() => {
    if (email.length) {
      sendEmailLink({
        variables: {
          email: email,
          url: window.location.href,
        },
      })
    }
  }, [email])

  return (
    <>
      <EmailModuleContainer feedbackShown={feedbackShown}>
        <InputSection>
          <h3>Get these results in an email</h3>
          <FineForm
            email
            submitted={submitted}
            setSubmitted={setSubmitted}
            setEmail={setEmail}
          />
          <p>
            {submitted
              ? `Email sent to ${email}`
              : `We won't collect your email or use it for anything else`}
          </p>

          {submitted && (
            <BlueButton onClick={() => setSubmitted(false)}>
              Send to another email
            </BlueButton>
          )}
        </InputSection>
        <RightSection feedback={feedback}>
          {!feedbackShown && (
            <h3>
              {feedbackSubmitted
                ? "Thanks for the feedback"
                : feedback
                ? "Was this page helpful?"
                : "Do you have multiple fines?"}
            </h3>
          )}
          {feedback && !feedbackShown && !feedbackSubmitted ? (
            <FeedbackButtons>
              <BlueButton
                onClick={() => {
                  setHelpful("Yes")
                  setFeedbackShown(true)
                }}
              >
                Yes
              </BlueButton>
              <BlueButton
                onClick={() => {
                  setHelpful("No")
                  setFeedbackShown(true)
                }}
              >
                No
              </BlueButton>
            </FeedbackButtons>
          ) : !feedback ? (
            <ul>
              <li>
                <Link to="/questions">Start fixing another fine</Link>
              </li>
              {/* <li>
            <a href="/">Get help from your local Community Legal Centre</a>
          </li> */}
            </ul>
          ) : null}
        </RightSection>
      </EmailModuleContainer>
      {feedbackShown && <FeedbackModule submitted={feedbackSubmitted} setFeedbackSubmitted={setFeedbackSubmitted} setFeedbackShown={setFeedbackShown} />}
    </>
  )
}

export default EmailModule
