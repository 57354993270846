import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"

export const ReviewPageContainer = styled.div`
  color: #111;
`

export const DoubleBlockContainer = styled(MainColumn)`
  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const ContentWrapper = styled.div`
  @media ${device.tablet} {
    width: 50%;
    padding-right: 30px;
  }

  div.title,
  h3 {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 34px;
    display: flex;
    align-items: center;

    svg {
      height: 34px;
      width: 34px;
      margin-right: 17px;
    }
  }

  p {
    margin-bottom: 34px;
    :last-child {
      margin-bottom: 0;
    }
  }
  p,
  h4,
  ul,
  ol {
    font-size: 16px;
    line-height: 200%;
    font-weight: 400;
    @media ${device.tablet} {
      font-size: 18px;
    }
  }

  ul,
  ol {
    padding-left: 23px;
    list-style-type: disc;
    color: #111111;

    li {
      margin-bottom: 20px;
    }
  }

  ol {
    list-style-type: decimal;
    ul {
      list-style-type: circle;
      margin-top: 15px;
      margin-left: 0;
    }
  }

  a {
    color: #55789b;
    text-decoration: underline;
  }

  h4 {
    margin: 10px 0;
    font-weight: 500;
    line-height: 100%;
  }
`

export const DetailedSummaryContent = styled(ContentWrapper)`
  padding-top: 60px;
  padding-bottom: 60px;
`

export const SectionDetailsContent = styled(DetailedSummaryContent)`
  position: relative;

  p {
    margin-bottom: 30px;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    width: 100vw;
    height: 100%;
    background: #f7f7f7;
    z-index: -1;

    @media ${device.tablet} {
      transform: none;
      right: 0;
    }
  }
  @media ${device.tablet} {
    padding-top: 44px;
    padding-bottom: 44px;
    width: 50%;
  }
`

export const ImportantContent = styled(SectionDetailsContent)`
  :before {
    display: none;
  }
  @media ${device.tablet} {
    padding-left: 30px;
  }

  p {
    margin: 0;
  }
`

export const FineOptionDetailsContent = styled(ContentWrapper)`
  padding-top: 72px;
  padding-bottom: 72px;

  p {
    color: #111111;
  }
`

export const Tip = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-bottom: 30px;

  :last-of-type {
    margin-bottom: 0;
  }
  h3 {
    color: #55789b;
    margin-bottom: 10px;
    font-size: inherit;
    font-weight: 500;
  }

  p {
    color: #111111;
    margin-bottom: 0;
    font-size: inherit;
  }
`

export const FineSteps = styled.div`
  font-size: 18px;
  overflow: hidden;

  @media ${device.tablet} {
    padding-left: 44px;
  }

  > * {
    font-size: inherit;
  }
  h3 {
    color: #55789b;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;

    @media ${device.tablet} {
      position: relative;
      &:before {
        content: "";
        width: 2px;
        height: 1000px;
        top: 11px;
        background: #6d93b1;
        position: absolute;
        left: -35px;
      }

      &:after {
        content: "";
        width: 8px;
        height: 8px;
        background: #6d93b1;
        position: absolute;
        left: -38px;
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 3px;
      }
    }
  }

  ul {
    font-size: 18px;
  }

  ul > li,
  p {
    margin-bottom: 0;
    color: #111111;
    font-size: 18px;
  }
`

export const Step = styled.div`
  font-size: inherit;
  margin-bottom: 50px;

  :last-of-type {
    h3:before {
      background: #f7f7f7;
    }
  }
`
export const LinksAndFiles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 0 0;
  @media ${device.tablet} {
    padding-top: 44px;
    width: 50%;
  }
  div.title {
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 34px;
  }
`

export const LinkList = styled.ul`
  list-style: none;
  margin-bottom: 0;
  li {
    margin-bottom: 30px;



    a {
      color: #55789b;
      text-decoration: underline;
      font-size: 18px;
      font-weight: 500;
    }
  }
`

export const FileList = styled.ul`
  list-style: none;
`

export const File = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const FileImg = styled.div`
  height: 82px;
  width: 60px;
  margin-bottom: 30px;

  @media ${device.tablet} {
    margin-bottom: 0;
    margin-right: 30px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

export const FileDetails = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: #55789b;
    text-decoration: underline;
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 500;

    @media ${device.tablet} {
      margin-top: 8px;
    }
  }
`
