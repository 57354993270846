import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { FineOption } from "../../components/FineOption"

export default  ({ data }) => {
  return (
    <Layout>
      <SEO title={data.wpFineoption.title}/>
      <FineOption data={data.wpFineoption} />
    </Layout>
  )
}

export const query = graphql`
  query fineoption($id: String!) {
    wpFineoption(id: { eq: $id }) {
      title
      #featuredImage {
      #  remoteFile {
      #    childImageSharp {
      #      fluid(maxWidth: 1440) {
      #        ...GatsbyImageSharpFluid_tracedSVG
      #      }
      #    }
      #  }
      #}
      top {
        shortSummary
        summary
        sectionTitle
        whenShould
        thingsKnow {
          title
          description
        }
      }
      details {
        detailTitle
        detailDescription
      }
      steps {
        stepHeading
        steps {
          stepDescription
          stepTitle
        }
      }
      linksAndFiles {
        filesTitle
        files {
          file {
            title
            mediaItemUrl
            mediaDetails {
              file
              sizes {
                mimeType
                file
                height
              }
            }
            type
            mimeType
            mediaType
          }
        }
        linksTitle
        links {
          linkTitle
          url
        }
      }
    }
  }
`
