import React from "react"
import { WarningModuleContainer, TextSection } from "./style"

const WarningModule = () => {
  return (
    <WarningModuleContainer>
      <TextSection>
        <h3>

          <svg id="icon-caution" viewBox="0 0 32 32">
            <title>caution</title>
            <path d="M15.114 25.751h1.829v-1.829h-1.829z"></path>
            <path d="M16.943 22.094v-12.096h-1.829v12.096z"></path>
            <path d="M16.028 1.829l-16.028 27.762h32.057l-16.028-27.762zM16.028 5.486l12.862 22.277h-25.723l12.861-22.277z"></path>
          </svg>
          Please don’t ignore your fine
        </h3>
        <p>
          If you do not take action by the due date you may pay more and be stopped from driving or using your car.
        </p>
      </TextSection>
    </WarningModuleContainer>
  )
}

export default WarningModule
